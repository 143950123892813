export interface IOffer {
  item: IOfferItem;
  offerDealType: EOfferDealTypes;
  description: IOfferDescription;
  purchaseQuantity: number;
}

export interface IOfferItem {
  itemId: string;
  itemName: string;
  itemQuantity: number;
  itemMinShippingTime: number;
  itemMaxShippingTime: number;
  itemShippingQuant: number;
  itemShippingPrice: number;
  itemMinShipping: number;
  itemMinOfferPrice: number | null;
  valueAddedTax: number;
}

export interface IOfferDescription {
  itemDescription: string;
  itemMarketTerms: string;
  itemPurchaseTerms: IOfferTerms;
  itemAdditionalInfo: IOfferAdditionalInfo;
}

export interface IOfferAdditionalInfo {
  weight: number;
  material: IProductMaterial;
  color: string;
  warranty: IWarranty;
}

export interface IProductMaterial {
  part: EProductParts;
  value: string;
}

export interface IWarranty {
  type: EWarrantyTypes;
  value: number;
}

export interface IOfferTerms {
  shippingCostByQuantity: Array<IOfferShippingCostByQuantity>;
  shippingCostByAmount: Array<IOfferShippingCostByAmount>;
}

export interface IOfferShippingCostByQuantity {
  price: number;
  cost: number;
}

export interface IOfferShippingCostByAmount {
  amount: number;
  price: number;
}

export enum EWarrantyTypes {
  Month = 'месяц',
  Year = 'год',
  Day = 'день',
}

export enum EOfferDealTypes {
  BestPrice = 'bestPrice',
  FastShipping = 'fastShipping',
  None = 'none',
}

export enum EProductParts {
  Body = 'изделия',
  Handle = 'ручки',
}

export interface IOfferEmitValue {
  id: number;
  purchasingQuantity: number;
  price: number;
}

export interface IOfferRaw {
  id: number; // offer id
  supplier: string; // поставщик
  brandId: number;
  categoryId: string;
  active: boolean;
  modelId: number; // id привязанной модели к офферу
  hidden: boolean; // признак, что оффер удален (hidden === true)
  hintModels: Array<IOfferRawHintModel>;
  hintCategories: Array<IOfferRawHintCategory>;
  data: IOfferRawData;
  catalog?: number
}

export interface IOfferRawData {
  name: string; // имя
  vendor: string; // производитель
  model: string; // модель
  vendorCode: string; // артикул
  category: string;
  price: number; // цена
  images: Array<string>; // картинки
  barcodes: Array<string>;
  packageWeight: number;
  packageLength: number;
  packageHeight: number;
  packageWidth: number;
  countryOfOrigin: string;
  description: string;
  sku?: string;
}

export interface IOfferRawHintModel {
  id: number;
  prob: number;
  brandId: number;
  categoryId: number;
}

export interface IOfferRawHintCategory {
  id: number;
  prob: number;
}
